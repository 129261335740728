import {MeetService} from './meet';
import {LocalStorage} from './storage';
import {UserSettings} from './settings/user-settings';
import {User} from 'firebase/auth';

let meetService: MeetService;
let localStorageService: LocalStorage;
let userSettings: UserSettings;

const additionalServices: { [k: string]: any } = {};

const getToken = async (user: User) => {
    let token: string = '';

    if (!token) {
        token = await user.getIdToken(true);
    }

    return () => {
        return {
            token,
            getRefreshedToken: async () => {
                token = await user.getIdToken(true);
                return token;
            }
        }
    }
}
export const initIoc = async (user?: User) => {
    if (!user) {
        localStorageService = new LocalStorage();

        return;
    }
    const getTokenFn = await getToken(user);

    localStorageService = new LocalStorage();
    meetService = new MeetService(getTokenFn);
    userSettings = new UserSettings(getTokenFn);
}

export const addService = (name: string, service: any) => {
    additionalServices[name] = service;
}

export const getIoc = (): {
    meetService: MeetService,
    localStorageService: LocalStorage,
    userSettings: UserSettings,
    [k: string]: any
} => {
    return {
        meetService,
        localStorageService,
        userSettings,
        ...additionalServices
    }
}
