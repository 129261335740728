import React, {Suspense} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.scss';
import {addService, getIoc, initIoc} from './services/ioc';
import {catchError, EMPTY, startWith, tap} from 'rxjs';
import {askExtensionForUser} from './services/plugin/communication';
import {bind} from '@react-rxjs/core';
import {AuthService} from './services/auth.service';
import {createSignal} from '@react-rxjs/utils';
import {Loading} from 'react-daisyui';
// import GuardedRoute from './components/guarded-route/guarded-route';
// import Layout from './components/v2/layout';
// import MeetingListComponent from './components/v2/meeting-list/meeting-list.component';
// import DashboardRouteComponent from './components/v2/dashboard/dashboard-route.component';
// import SettingsComponent from './components/v2/settings/settings.component';
// import OnboardingComponent from './components/v2/onboarding/onboarding.component';
// import RestrictedForAuthUsersRoute from './components/guarded-route/restrict-auth';
// import LoginComponent from './components/v2/login/login.component';
// import UninstallComponent from './components/v2/uninstall/uninstall.component';


const [isReady, onIsReady] = createSignal<boolean>();
const [isOnboadred, onIsOnboarded] = createSignal<boolean>();


const authService = new AuthService();
const [useUser] = bind(authService.getUserObservable()
    .pipe(
        startWith(undefined),
        tap(() => onIsReady(false)),
        tap(async user => {
            if (user === null) {
                onIsReady(true);
                await initIoc();
                addService('authService', authService);
                return;
            }
            if (user) {
                await initIoc(user);
                addService('authService', authService);
                const settings = await getIoc().userSettings.getSettings();
                if (settings?.authError) {
                    await getIoc().authService.logout();
                }
                onIsOnboarded(settings?.onboardingComplete || false);
                onIsReady(true);
                const isExtensionNeedsUser = await askExtensionForUser();
                if (isExtensionNeedsUser) {
                    await authService.updateUserOnExtension(user as any);
                }
            }


        }),
        catchError(error => {
            console.error(error);
            return EMPTY;
        })
    ), undefined);

const [useIsReady] = bind(isReady, false);
const [useIsOnboarded] = bind(isOnboadred, true);

function App() {
    const user = useUser();
    const isOnboarded = useIsOnboarded();
    const isReady = useIsReady();

    if (!isReady) {
        return <div className="h-screen w-screen flex justify-center"><Loading variant="dots"/></div>;
    }

    // need lazy because of dependecies
    const RestrictedForAuthUsersRoute = React.lazy(() => import('./components/guarded-route/restrict-auth'));
    const GuardedRoute = React.lazy(() => import('./components/guarded-route/guarded-route'));
    const Layout = React.lazy(() => import('./components/v2/layout'));
    const LoginComponent = React.lazy(() => import('./components/v2/login/login.component'));
    const MeetingListComponent = React.lazy(() => import('./components/v2/meeting-list/meeting-list.component'));
    const DashboardRouteComponent = React.lazy(() => import('./components/v2/dashboard/dashboard-route.component'));
    const UninstallComponent = React.lazy(() => import('./components/v2/uninstall/uninstall.component'));
    const OnboardingComponent = React.lazy(() => import('./components/v2/onboarding/onboarding.component'));
    const SettingsComponent = React.lazy(() => import('./components/v2/settings/settings.component'));

    return (
        <Suspense fallback={<div className="w-screen h-screen flex justify-center"><Loading className="h-screen"
                                                                                            variant="dots"/></div>}>
            <Router>
                <Routes>
                    <Route element={
                        <GuardedRoute user={user} onboardingCompleted={isOnboarded}/>
                    }>
                        <Route path="/" element={<Layout element={<MeetingListComponent/>}/>}/>
                        <Route path="/dashboard" element={<Layout element={<DashboardRouteComponent/>}/>}/>
                        <Route path="/settings" element={<Layout element={<SettingsComponent/>}/>}/>


                        <Route path="/:id" element={<Layout element={<MeetingListComponent/>}/>}/>

                        <Route path="/onboarding"
                               element={<OnboardingComponent onboardCompletedFn={() => onIsOnboarded(true)}/>}/>
                        <Route path="/onboarding/:step"
                               element={<OnboardingComponent onboardCompletedFn={() => onIsOnboarded(true)}/>}/>

                    </Route>
                    <Route element={<RestrictedForAuthUsersRoute user={user}/>}>
                        <Route path="/login" element={<LoginComponent/>}/>


                    </Route>
                    <Route path="/uninstall" element={<Layout element={<UninstallComponent/>}/>}/>
                </Routes>
            </Router>
        </Suspense>
    );
}

export default App;
