import {OnboardingCompleteEndpoint, SettingsEndpoint} from '../../constants/api';
import {SettingsProps} from '../../types/user-settings.type';
import {Api} from '../api';
import {RuntimeStorage} from '../runtime-storage';

export class UserSettings extends Api {

    public getDefault(): SettingsProps {
        return {
            noSummaryEmails: false,
            summaryEmails: true,
            redirectEmails: '',
            whiteEmailList: [],
            onboardingComplete: false,
        };
    }

    public async isPremium(): Promise<boolean> {
        const settings = await this.getSettings();

        return settings.isPremium || false;
    }

    public async onboardingComplete() {
        const response = await this.fetch(OnboardingCompleteEndpoint, {
            method: 'PUT',
        });

        return await response?.json();
    }

    public async getSettings(): Promise<SettingsProps & { authError: boolean }> {
        if (RuntimeStorage.getItem('settings')) {
            return RuntimeStorage.getItem('settings');
        }
        const response = await this.fetch(SettingsEndpoint, {
            method: 'GET',
        });

        const data = await response?.json();
        const defaultSettings = this.getDefault();

        const settings = {
            noSummaryEmails: data?.sendNoSummary !== undefined ? data.sendNoSummary : defaultSettings.noSummaryEmails,
            onboardingComplete: data?.onboardingComplete !== undefined ? data.onboardingComplete : false,
            summaryEmails: data?.sendSummary !== undefined ? data.sendSummary : defaultSettings.summaryEmails,
            redirectEmails: data?.redirectEmails !== undefined ? data.redirectEmails : defaultSettings.redirectEmails,
            whiteEmailList: data?.whiteEmailList !== undefined ? data.whiteEmailList : defaultSettings.whiteEmailList,
            isPremium: data?.isPremium !== undefined ? data.isPremium : false,

            authError: response?.status === 401,
        };

        RuntimeStorage.setItem('settings', settings);

        return settings;
    }

    public async updateSettings(settings: {
        sendNoSummary?: boolean,
        sendSummary?: boolean,
        whiteEmailList?: string[]
    }) {
        const response = await this.fetch(SettingsEndpoint, {
            method: 'PUT',
            body: JSON.stringify(settings),
        });

        if (response?.status === 200) {
            RuntimeStorage.setItem('settings', {
                ...await this.getSettings(),
                ...settings,
            });
        }

        return await response?.json();
    }
}
