export class RuntimeStorage {
    private static storage: Map<string, any> = new Map<string, any>();

    public static setItem(key: string, value: any) {
        RuntimeStorage.storage.set(key, value);
    }

    public static hasItem(key: string): boolean {
        return RuntimeStorage.storage.has(key);
    }

    public static getItem(key: string): any {
        return RuntimeStorage.storage.get(key);
    }
}
