export const NewGoogleMeet = 'https://meet.google.com/new';
export const IsUserAuthorizedKey = 'isUserAuthorized';

export const ChromeExtensionId = 'hjhndnggmbigejckepdgkfaelajimbdl';

export const PluginUrl = 'https://chromewebstore.google.com/detail/mbox-ai-meetbeta/hjhndnggmbigejckepdgkfaelajimbdl';

export const GroupDateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit'};

export const MinMessagesForSummary = 5;

export const MBoxDebounceTimeMS = 10;

// todo pagination
export const MBoxMeetingLimit = 1000;
