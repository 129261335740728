import {IsUserAuthorizedKey} from '../constants';
import {getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup} from 'firebase/auth';
import app from '../firebase.app';
import {Observable} from 'rxjs';
import {sendUserToExtension} from './plugin/communication';
import {CustomTokenEndpoint} from '../constants/api';

export class AuthService {
    private readonly googleProvider = new GoogleAuthProvider();
    private readonly app = app;

    public async login() {
        const auth = getAuth();
        try {
            const result = await signInWithPopup(auth, this.googleProvider)
            const user = result.user;

            const userToken = await user.getIdToken();
            const customToken = await this.createCustomToken(userToken || '');
            if (!customToken?.token) {
                console.log('Warning: custom token is not created');
            }
            sendUserToExtension(user, customToken?.token || '');

            return user;
        } catch (error: any) {
            console.error(error)
            // Handle Errors here.
            // const errorCode = error.code;
            // const errorMessage = error.message;
            // // The email of the user's account used.
            // const email = error.customData?.email;
            // // The AuthCredential type that was used.
            // const credential = GoogleAuthProvider.credentialFromError(error);
            // // ...
            //
            // // console.log(errorCode, errorMessage, email, credential)

            return null
        }

    }

    public async createCustomToken(token: string) {
        const res = await fetch(CustomTokenEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        return await res.json()
    }

    public async logout() {
        await getAuth(this.app).signOut()
        localStorage.removeItem(IsUserAuthorizedKey);
    }

    public async updateUserOnExtension(user: any) {
        const userToken = await user.getIdToken();
        const customToken = await this.createCustomToken(userToken || '');
        if (!customToken?.token) {
            console.log('Warning: custom token is not created');
        }
        sendUserToExtension(user, customToken?.token || '');
    }

    public getUserObservable() {
        return new Observable<any | null>(observer => {
            const auth = getAuth(this.app);
            return onAuthStateChanged(auth, async (user) => {
                if (user) {
                    observer.next(user);
                } else {
                    observer.next(null);
                }
            });
        });
    }
}
