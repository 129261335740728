import {API_BASE_URL} from './base';

export const PromptEndpoint = `${API_BASE_URL}/v1/prompts`;
export const RePromptEndpoint = `${API_BASE_URL}/v1/prompts`;
export const CustomTokenEndpoint = `${API_BASE_URL}/v1/auth/extension-token`;
export const TranslateEndpoint = `${API_BASE_URL}/v1/translate`;
export const SettingsEndpoint = `${API_BASE_URL}/v1/user-settings`;
export const OnboardingCompleteEndpoint = `${API_BASE_URL}/v1/user-settings/onboarding-complete`;
export const MeetingListEndpoint = `${API_BASE_URL}/v1/meeting-list`;
export const MeetingByIDEndpoint = `${API_BASE_URL}/v1/meeting/`;
export const SummaryListEndpoint = `${API_BASE_URL}/v1/summary-list`;
export const MessageMarkEndpoint = `${API_BASE_URL}/v1/message/mark`;
export const MessageAIFixEndpoint = `${API_BASE_URL}/v1/p/message/ai-fix`;
export const MessageEncryptEndpoint = `${API_BASE_URL}/v1/p/message/encrypt`;
