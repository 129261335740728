import {GoogleMeetWithMessages} from '../../types/full-meeting.type';
import {Meet} from '../../types/meet.type';
import {
    MeetingByIDEndpoint,
    MeetingListEndpoint, MessageAIFixEndpoint, MessageEncryptEndpoint,
    MessageMarkEndpoint,
    PromptEndpoint,
    RePromptEndpoint,
    SummaryListEndpoint,
    TranslateEndpoint
} from '../../constants/api';
import {TranslateType} from '../../constants/translate';
import {Summary} from '../../types/summary';
import {Api} from '../api';
import {MBoxMeetingLimit} from '../../constants';

export class MeetService extends Api {

    public async encryptMessage(messageId: string) {
        const response = await this.fetch(`${MessageEncryptEndpoint}/${messageId}`, {
            method: 'PUT',
        });

        return await response?.json();
    }

    public async getGroupedMeetList(limit = MBoxMeetingLimit, offset?: string): Promise<{ [k: string]: Meet[] }> {
        const meets = await this.getMeetList(limit, offset);
        if (!meets || !meets.length) {
            return {};
        }

        const groupedMeets: { [key: string]: Meet[] } = {};
        meets?.forEach((meet) => {
            meet.users = meet.users || [];
            const date = new Date(meet.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
            if (!groupedMeets[date]) {
                groupedMeets[date] = [];
            }
            groupedMeets[date].push(meet);
        });

        return groupedMeets;
    }

    public async getMeetList(limit = 100, offset?: string): Promise<Meet[]> {
        try {
            const params = new URLSearchParams();
            params.append('limit', limit.toString());
            if (offset) {
                params.append('offset', offset);
            }

            const response = await this.fetch(`${MeetingListEndpoint}?${params.toString()}`, {
                method: 'GET',
            });

            return await response?.json() || [];
        } catch (ex) {
            console.log('Error:', ex);

            return [];
        }

    }

    public async removeMeet(meetKey: string) {
        const response = await this.fetch(`${MeetingByIDEndpoint}${meetKey}`, {
            method: 'DELETE',
        });

        return !!await response?.json();
    }


    public async promptMeet(meetId: string, promptKey: string) {
        const response = await this.fetch(PromptEndpoint, {
            method: 'POST',
            body: JSON.stringify({
                meetKey: meetId,
                promptKey
            }),
        });

        return await response?.json();
    }

    public async translateSummary(meetId: string, summaryKey: string, translateTo: string) {
        const response = await this.fetch(TranslateEndpoint, {
            method: 'POST',
            body: JSON.stringify({
                meetKey: meetId,
                type: TranslateType.Summary,
                summaryKey,
                languageKey: translateTo
            }),
        });

        return await response?.json();
    }

    public async updateSummary(meetId: string, docId: string, promptKey: string) {

        const response = await this.fetch(`${RePromptEndpoint}/${docId}`, {
            method: 'PUT',
            body: JSON.stringify({
                meetKey: meetId,
                promptKey,
            }),
        });

        return await response?.json();

    }

    async getById(meetId: string): Promise<GoogleMeetWithMessages | null> {
        const response = await this.fetch(`${MeetingByIDEndpoint}${meetId}`, {
            method: 'GET',

        });
        const data = await response?.json();

        return {
            ...(data?.meet || {}),
            messages: data?.messages || [],
        };

    }

    async getSummaries(meetId: string): Promise<{
        summaries: Summary[],
        summaryDisabled: boolean
    }> {
        const response = await this.fetch(`${SummaryListEndpoint}/${meetId}`, {
            method: 'GET',
        });

        const data = await response?.json();

        if (!data) {
            return {summaries: [], summaryDisabled: false}
        }

        return {summaries: data?.summaries || [], summaryDisabled: data?.summariesDisabled || false}
    }

    public async markMessage(messageKey: string, markType: string[]) {
        const response = await this.fetch(`${MessageMarkEndpoint}/${messageKey}`, {
            method: 'PUT',
            body: JSON.stringify({marks: markType}),
        });

        return await response?.json();
    }

    public async aiFixMessage(messageId: string) {
        const response = await this.fetch(`${MessageAIFixEndpoint}/${messageId}`, {
            method: 'PUT',
        });

        return await response?.json();
    }

}
